.contact-cta {

	&__wrapper {
		background: $darkLeftLightRight;
		opacity: 0;
		padding: $spacing;
		position: relative;
		transition: opacity $animationDuration;

		&.in-view {opacity: 1;}
	}

	p {
		margin: 0 auto 2rem;
		max-width: 45rem;
	}

	@include media($screen-sm) {
		&__wrapper {
			padding: $spacing*2;
		}
	}

	@include media($screen-md) {
		&__wrapper {
			padding: $spacing*3;
			padding-right: 9rem;
		}

		.button {
			font-size: 1.125rem;
			padding: 1rem 3rem;
		}
	}
}