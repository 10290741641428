.partnerships {

	&__text {
		margin-bottom: 3rem;
		opacity: 0;
		transition: opacity $animationDuration;

		&.in-view {opacity: 1;}
	}

	&__graphic {
		height: 0;
		padding-bottom: 100%;
		position: relative;

		&--outer,
		&--mid,
		&--inner,
		&--center {
			border-radius: 50%;
			border: 1px solid $gray;
			position: absolute;
				top: 50%;
				left: 50%;
			transform: translate(-50%, -50%);
		}

		&--outer {
			@include size(100%);
		}

		&--mid {
			@include size(75%);
		}

		&--inner {
			@include size(50%);
		}

		&--center {
			@include size(25%);
			background-color: white;
			border: none;
			box-shadow: .5rem .5rem 1rem -.5rem rgba($black, .2);

			.partnerships__graphic--icon {
				@include size(50%);
				transform: translate(-50%, -50%);

				img {
					height: 100% !important;
					width: 100% !important;
				}
			}
		}

		&--icon {
			position: absolute;
				top: 50%;
				left: 50%;
			transform-origin: center center;

			img {
				@include object-fit(contain, top);
				@include size(10%);
			}

			&::before {
				background: $white;
				border-radius: 50%;
				content: '';
				height: 16%;
				width: 16%;
				position: absolute;
					top: -3%;
					left: 42%;
			}

			&.outer-ring {
				@include size(109%);
				$outerSpeed: 27s;
				z-index: 2;
				
				&:nth-child(1) {
					animation: circlePathZero $outerSpeed linear infinite;

					&::before {
						box-shadow: 2px 2px 1rem -.5rem rgba($black, .5);
					}
				}
				&:nth-child(2) {
					animation: circlePathQuarter $outerSpeed linear infinite;

					&::before {
						box-shadow: 2px -2px 1rem -.5rem rgba($black, .5);
					}
				}
				&:nth-child(3) {
					animation: circlePathHalf $outerSpeed linear infinite;

					&::before {
						box-shadow: -1px -2px 1rem -.5rem rgba($black, .5);
					}
				}
				&:nth-child(4) {
					animation: circlePathThreeQuarter $outerSpeed linear infinite;

					&::before {
						box-shadow: -2px -2px 1rem -.5rem rgba($black, .5);
					}
				}


				&::before {animation: reverseRotate $outerSpeed linear infinite;}
				// img {animation: reverseRotateImg $outerSpeed linear infinite;}
			}

			$midSpeed: 22s;

			&.mid-ring {
				@include size(82.5%);

				&:nth-child(1) {
					animation: circlePathZero $midSpeed linear infinite;

					&::before {
						box-shadow: 2px 2px 1rem -.5rem rgba($black, .5);
					}
				}
				&:nth-child(2) {
					animation: circlePathQuarter $midSpeed linear infinite;

					&::before {
						box-shadow: 2px -2px 1rem -.5rem rgba($black, .5);
					}
				}
				&:nth-child(3) {
					animation: circlePathHalf $midSpeed linear infinite;

					&::before {
						box-shadow: -1px -2px 1rem -.5rem rgba($black, .5);
					}
				}
				&:nth-child(4) {
					animation: circlePathThreeQuarter $midSpeed linear infinite;

					&::before {
						box-shadow: -2px -2px 1rem -.5rem rgba($black, .5);
					}
				}

				&::before {animation: reverseRotate $midSpeed linear infinite;}
			}
		}
	}

	@include media($screen-sm) {
		&__text {
			margin-bottom: 0;
			padding-right: 4.5rem;
			width: 50%;
		}
	
		&__graphic {
			padding-bottom: 50%;
			width: 50%;
		}
	}
}

@keyframes circlePathZero {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

@keyframes circlePathQuarter {
	0% {
		transform: translate(-50%, -50%) rotate(90deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(450deg);
	}
}

@keyframes circlePathHalf {
	0% {
		transform: translate(-50%, -50%) rotate(180deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(540deg);
	}
}


@keyframes circlePathThreeQuarter {
	0% {
		transform: translate(-50%, -50%) rotate(270deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(630deg);
	}
}

@keyframes reverseRotate {
	0% {transform: rotate(360deg) ;}
	100% {transform: rotate(0deg);}
}

@keyframes reverseRotateImg {
	0% {transform: rotate(360deg) translate(-50%,-50%);}
	100% {transform: rotate(0deg) translate(-50%,-50%);}
}