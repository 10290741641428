.two-column-collage {

	.animation-trigger.in-view~ {
        .two-column-collage__text {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

	&__text {
		opacity: 0;
        position: relative;
		margin-bottom: 3rem;
        transition: opacity $animationDuration ease-in-out;
	}

	&__collage {
		height: 0;
		padding-bottom: 100%;
		position: relative;
	}

	&__image {
		border: 2px solid white;
		box-shadow: 0 0 1rem -.5rem rgba(0,0,0,.5);
		height: 0;
		opacity: 0;
		position: absolute;
		transition: $animationDuration opacity;

		img { @include object-fit(cover, center); }

		&.in-view {opacity: 1;}

		&:nth-child(1) {
			top: 5%;
			left: 0;
			padding-bottom: 32%;
			width: 40%;
		}

		&:nth-child(2) {
			top: 0;
			left: 25%;
			padding-bottom: 40%;
			transition-delay: $animationStagger;
			width: 60%;
			z-index: 1;
		}

		&:nth-child(3) {
			top: 6%;
			right: 0;
			padding-bottom: 34%;
			transition-delay: $animationStagger*4;
			width: 40%;
		}

		&:nth-child(4) {
			top: 22%;
			left: 18%;
			padding-bottom: 15%;
			transition-delay: $animationStagger*3;
			width: 20%;
			z-index: 2;
		}

		&:nth-child(5) {
			top: 25%;
			left: 55%;
			padding-bottom: 18%;
			transition-delay: $animationStagger*2;
			width: 28%;
			z-index: 2;
		}

		&:nth-child(6) {
			top: 36%;
			left: 10%;
			padding-bottom: 18%;
			transition-delay: $animationStagger*5;
			width: 25%;
			z-index: 2;
		}

		&:nth-child(7) {
			top: 32%;
			left: 33%;
			padding-bottom: 19%;
			transition-delay: $animationStagger*6;
			width: 30%;
			z-index: 1;

			img {object-position: 50% 27%;}
		}

		&:nth-child(8) {
			top: 37%;
			left: 62%;
			padding-bottom: 25%;
			transition-delay: $animationStagger*7;
			width: 25%;
			z-index: 1;
		}

		&:nth-child(9) {
			top: 50%;
			left: 15%;
			padding-bottom: 15%;
			transition-delay: $animationStagger*8;
			width: 25%;
			z-index: 2;
		}

		&:nth-child(10) {
			top: 51%;
			left: 27%;
			padding-bottom: 38%;
			transition-delay: $animationStagger*6;
			width: 63%;

			img {object-position: 50% 0%;}
		}
	}

	@include media($screen-sm) {
		&__text {
			margin-bottom: 0;
			padding-right: $spacing*2;
			width: 50%;
		}

		&__collage {
			padding-bottom: 50%;
			width: 50%;
		}
	}

	@include media($screen-md) {
		&__text {
			width: 45%;
		}

		&__collage {
			padding-bottom: 55%;
			width: 55%;
		}
	}
}