/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(90deg, #061C2E 0%, #11538B 100%), var(--White, #FFF);
	overflow: hidden;
	clear: both;
	margin-top: auto; // Sticky footer

	.contain {
		padding: 0 $spacing;
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	nav {font-family: $font-montserrat;}

	// Default link styles
	a {
		font-weight: 400;
		text-decoration: none;
		
		&:hover { text-decoration: underline; }
	}

	&__business {
		margin-bottom: $spacing;

		ul, li {
			display: block;
		}

		li {
			margin-bottom: .75rem;
		}
	}

	&__phone,
	&__schedule,
	&__email {
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 1.5rem;
		padding-left: 2.25rem;
		position: relative;
	}

	&__phone {background-image: url('/icons/phone.svg');}
	&__schedule {background-image: url('/icons/schedule.svg');}
	&__email {background-image: url('/icons/mail.svg');}

	&__top {
		padding: 3rem 0;
        
        &--subscribe {
            button.subscribe {
                border-radius: 0;
                border-start-end-radius: .5rem;
                border-end-end-radius: .5rem;
                font-size: .875rem;
                font-weight: 400;
                padding: .75rem 2rem;
                text-transform: uppercase;
            }
            
            input[type=email] {
                border-start-end-radius: 0;
                border-end-end-radius: 0;
                margin: 0;
                
                &::placeholder {
                    color: $gray;
                }
            }
        }
	}

	&__navigation {

		.footer-menu__item {
			a {
				display: block;
				font-weight: 400;
				padding: .25rem 0;

				&:after {
					background: $white;
					content: '';
					display: block;
					height: 1px;
					position: relative;
						top: 3px;
					transform: scaleX(0);
					transform-origin: 100% 0;
					transition: transform .25s ease-in-out;
				}
	
				&:hover,
				&:focus {
					text-decoration: none;

					&:after {
						transform: scaleX(1);
						transform-origin: 0 100%;
					}
				}
			}

			&.toplevel {
				margin-bottom: 1rem;

				& > a {
					@include font-scale( 2, $headingFontSize);
					color: $white;
					font-family: $font-bebas-neue;
					font-weight: 700;
					letter-spacing: 1px;
					padding-top: 0;
				}
			}
		}

		li { display: block; }
	}
    
	.social-media {
		margin: 1em 0;

		&__link {
			display: inline-block;
			margin-right: 1rem;
		}

		a {
			display: block;
			width: 1.5rem;

			&:hover,
			&:focus {
				svg path {fill: $orange;}
			}
		}

		svg path {
			fill: $white;
			transition: fill .25s;
		}
	}

	&__credits {
		border-top: 1px solid rgba(194, 209, 217, 0.30);
		display: flex;
			align-items: flex-end;
			justify-content: space-between;
		padding: 1em 0;
		width: 100%;

		.legal-footer {
			display: inline-block;

			ul, li {display: inline-block;}

			.legal-menu__item {
				margin-right: 2rem;
			}
		}
	}

	@include media($screen-md-max, 'max') {
		text-align: center;

		&__top {

			&--subscribe {
				margin: 3rem 0;
			}
		}

		&__business {
			margin-left: auto;
			margin-right: auto;
			max-width: 14rem;
			text-align: left;
		}

		&__navigation {
			
		}

		.legal-footer {
			margin-bottom: $spacing;
		}
	}

	@include media($screen-md) {
		
		&__business {
			margin-bottom: $spacing*2;
		}

		&__navigation {
			> ul {
				display: flex;
					align-items: start;
					justify-content: space-evenly;
				margin: 0;
			}

			.footer-menu__item.toplevel {
				padding-right: $spacing;

				> a {
					@include font-scale( 3, $headingFontSize);
				}
			}
		}

		&__top {

			&--meta {
				width: 25%;
			}

			&--subscribe {
				width: 25%;
			}

			&--navigation {
				width: 50%;
			}
		}
	}
}
