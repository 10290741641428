/**
 * Two Columns Flex Layout
 * DBS Interactive
 */

 .two-columns {

	&__left,
	&__right {
		opacity: 0;
		position: relative;
        transition: opacity $animationDuration ease-in-out, transform $animationDuration ease-in-out;

		img {display: block;}

        &.has-img {
			opacity: 0;
			transition: opacity $animationDuration ease-in-out;
		}

		img {display: block;}
	}

	&__left {
        transform: translate3d(-2rem, 0, 0);
    }

    &__right {
        transform: translate3d(2rem, 0, 0);
    }

	.animation-trigger.in-view ~ {
		.has-img {opacity: 1;}

        .two-columns__left,
        .two-columns__right {
            opacity: 1;
            transform: translate3d(0,0,0);
        }
	}

	&:not(.full-bleed) {
        .has-img.natural {
            &::after {
                border-radius: .75rem;
            }
            
            img {
                @include border-radius;
            }
        }
    } 

	@include media($screen-sm-max, 'max') {
		&__left {margin-bottom: $spacing;}
        
		&.full-bleed {
			.has-img { 
				margin-left: -#{$spacing};
				margin-right: -#{$spacing};
			}
		}

		& + .full-bleed {
			margin-top: $spacing !important;
		}
	}

	@include media($screen-sm) {
        &:not(.full-bleed) {
            .has-img.natural {
                &::after {
                    border-radius: 1.375rem;
                }
                
                img {
                    @include border-radius;
                }
            }
        }
        
		&__left,
		&__right {
			width: calc(50% - #{$spacing});
		
			&.contain-img { img {@include object-fit(contain, center);} }
			&.cover { img {@include object-fit(cover, center);} }

			&:not(.has-img) {
				padding: 1.5rem 3rem;
			}
		}
        
		&.full-bleed {
			min-height: 30rem;
			position: relative;

			.contain {
				align-items: center;
				min-height: 30rem;
			}

			.two-columns__left:not(.has-img),
			.two-columns__right:not(.has-img) {
				padding: 3rem 0;
			}

			.two-columns__left:not(.has-img) {margin-right: auto;}
			.two-columns__right:not(.has-img) {margin-left: auto;}

			.has-img {
				height: 100%;
				overflow: hidden;
				position: absolute;
					top: 0;
				width: 50%;

				&.two-columns__left {left: 0;}
				&.two-columns__right {right: 0;}
				
				img { @include object-fit(cover, center); }
			}
		}

		&.two-thirds-right {
			.two-columns__left {
				@include media($screen-sm) {
					width: 38%;
				}
			}
	
			img { // ensures that the ratio effect is not lost by a fixed-width image.
				height: auto;
				width: 100%;
			}
	
			.two-columns__right {
	
				&.bg-img {padding-left: 0;}
	
				@include media($screen-sm) {width: calc(62% - 3rem);}
			}
		}
	
		// Left side larger
		&.two-thirds-left {
	
			img {
				// ensures that the ratio effect is not lost by a fixed-width image.
				height: auto;
				width: 100%;
			}
	
			@include media($screen-sm) {
				.two-columns__left {
					width: calc(62% - 3rem);
	
					&.bg-img {padding-right: 0;}
				}
	
				.two-columns__right {
					width: 38%;
				}
			}
		}
	}
    
    @include media($screen-md) {

		&.full-bleed {
			.two-columns__left:not(.has-img),
			.two-columns__right:not(.has-img) {
				padding: 4.5rem 0;
			}
		}
	}
}