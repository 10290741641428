/**
 * Two Column w/ Testimonial Flex Layout
 * DBS Interactive
 */

.two-column-with-testimonial {

    .animation-trigger.in-view~ {
        .has-img {
            opacity: 1;
        }

        .two-column-with-testimonial__content{
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .flickity-page-dots {
        bottom: $spacing;
        
        .dot {
            background: $grayDark;
        }
    }
    
    &__content,
    &__testimonial {
        position: relative;

        &.has-img {
            opacity: 0;
            transition: opacity $animationDuration ease-in-out;
        }

        img {
            display: block;
        }
    }

    &__content {
        opacity: 0;
        transform: translate3d(-2rem, 0, 0);
        transition: opacity $animationDuration ease-in-out, transform $animationDuration ease-in-out;

        ul {
            list-style-type: none;
            padding-left: 0;
            
            li {
                margin-bottom: $spacing;
                
                h4 {
                    color: $blue;
                    font-family: $font-montserrat;
                    margin-bottom: .25rem;
                    text-transform: none;
                }
                
                p {
                    font-size: 0.875rem;
                }
            }
        }
    }

    &__testimonial {
        padding: 0 !important;
        position: relative;
        transform: translate3d(2rem, 0, 0);

        &-wrapper {
            background: linear-gradient(275deg, rgba(226, 236, 244, 0.6) 2.35%, rgba(255, 249, 247, 0.6) 77.23%, transparent 100%);
            display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
            margin-right: $spacing;
            overflow: hidden;
            padding: #{$spacing * 4} #{$spacing * 2.5};
            width: 100%;
            
            svg {
                height: 71px;
                margin-bottom: $spacing;
                position: relative;
                width: 71px;
                z-index: 1;
            }
            
            .quote {
                position: relative;
                
                &::before {
                    background: url('/icons/quotes-bg.svg') no-repeat center;
                    content: '';
                    height: 6rem;
                    left: 50%;
                    position: absolute;
                    top: -2.3rem;
                    transform: translateX(-50%);
                    width: 8rem;
                }
            }
            
            .title {
                color: $blue;
            }
        }
        
        &.bg-blue-testimonials {
            .flickity-page-dots {
                .dot {
                    background: $gray;
                }
            }
            
            .two-column-with-testimonial__testimonial-wrapper {
                background: $blue !important;
                border-radius: 2rem;
                overflow: hidden;
                
                p {
                    color: $white;
                }
                
                .quote::before {
                    background: url('/icons/quotes-bg-white.svg');
                    height: 389px;
                    width: 495px;
                    top: -10.3rem;
                }
                
                .title {
                    font-family: $font-bebas-neue;
                    font-size: 1.375rem;
                }
                
                &.is-selected {
                    .quote::before {
                        z-index: 3;
                    }
                }
            }
        }
    }

    &.testimonial-left {
        .two-column-with-testimonial__content {
            order: 1;
        }
    }

    @include media($screen-sm) {
        .contain {
            padding-left: 1px !important;
        }
        
        &__content {
            &:not(.has-img) {
                padding: $spacing;
            }
        }

        &__content,
        &__testimonial {
            width: calc(50% - #{$spacing});

            &.contain-img {
                img {
                    @include object-fit(contain, center);
                }
            }

            &.cover {
                img {
                    @include object-fit(cover, center);
                }
            }
        }
        
        &__testimonial {
            height: fit-content;
            padding: $spacing;
        }

        &.two-thirds-right {
            .two-column-with-testimonial__content {
                @include media($screen-sm) {
                    width: 38%;
                }
            }

            img {
                // ensures that the ratio effect is not lost by a fixed-width image.
                height: auto;
                width: 100%;
            }

            .two-column-with-testimonial__testimonial {

                &.bg-img {
                    padding-left: 0;
                }

                @include media($screen-sm) {
                    width: calc(62% - 3rem);
                }
            }
        }

        // Left side larger
        &.two-thirds-left {

            img {
                // ensures that the ratio effect is not lost by a fixed-width image.
                height: auto;
                width: 100%;
            }

            @include media($screen-sm) {
                .two-column-with-testimonial__content {
                    width: calc(62% - 3rem);

                    &.bg-img {
                        padding-right: 0;
                    }
                }

                .two-column-with-testimonial__testimonial {
                    width: 38%;
                }
            }
        }
    }
    
    @include media($screen-sm-max, 'max') {
        &__content {
            margin-bottom: $spacing;
        }
        
        &__testimonial {
            margin-left: -2rem;
            width: 100%;
        }

        .two-column-with-testimonial__testimonial-wrapper {
            padding: 3rem 1.5rem 4.5rem;
        }
        
        &.testimonial-left {
            .two-column-with-testimonial__content {
                margin-bottom: 0;
            }
            
            .two-column-with-testimonial__testimonial {
                margin-bottom: $spacing;
            }
        }
    }

}