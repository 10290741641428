.brand-strip {

    &__heading {
        max-width: 47rem;
        opacity: 0;
        transition: $animationDuration opacity;
        text-align: center;

        &.in-view {opacity: 1;}
    }

    &__heading {
        margin: 0 auto $spacing;
    }

    &__strip {
        display: flex;
            align-items: center;
            flex-wrap: nowrap;
            gap: $spacing;

        // Mobile styles
        @include media ($screen-md-max, 'max') {
            flex-wrap: wrap;
        }
    }

    &__image {
        max-width: 15rem;
        opacity: 0;
        transform: translate3d(0,2rem,0);
        transition: $animationDuration opacity;
        padding: 1rem;

        &:nth-child(2) {transition-delay: $animationStagger;}
        &:nth-child(3) {transition-delay: $animationStagger*2;}
        &:nth-child(4) {transition-delay: $animationStagger*3;}
        &:nth-child(5) {transition-delay: $animationStagger*4;}
        &:nth-child(6) {transition-delay: $animationStagger*5;}
        &:nth-child(7) {transition-delay: $animationStagger*6;}
        &:nth-child(8) {transition-delay: $animationStagger*7;}

        &.in-view {
            opacity: 1;
            transform: translate3d(0,0,0);
        }

        img {
            max-height: 3rem;
            width: auto;
        }

        @include media ($screen-sm) {
            img {
                max-height: 4rem;
            }
        }
    }
    
    &.align-left {
        .brand-strip__heading {
            margin: 0;
            max-width: none;
            text-align: start;
        }
        
        .brand-strip__strip {
            justify-content: flex-start;
        }
    }
}