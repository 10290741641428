.admin {
	opacity: .75;
	position: fixed;
		right: 1.5rem;
		bottom: 1.5rem;
	transition: opacity .25s;

	&.active,
	&:hover,
	&:focus {
		opacity: 1;
	}

	&.active {
		.admin__links {
			opacity: 1;
			transition: opacity .25s 0s, visibility 0s .0s;
			visibility: visible;
		}
	}

	&__toggle {
		appearance: none;
		background: white;
		border: none;
		border-radius: 50%;
		box-shadow: 0 0 1rem -.5rem rgba($black, .75);
		height: 4rem;
		position: relative;
		width: 4rem;

		img {
			@include object-fit(contain, center);
			height: 75%;
			left: 55%;
			width: 75%;
		}
	}

	&__links {
		background: white;
		border-radius: 1rem;
		box-shadow: 0 0 1rem -.5rem rgba($black, .75);
		opacity: 0;
		padding: 1rem 1.5rem;
		position: absolute;
			bottom: 4.5rem;
			right: 0;
		transition: opacity .25s 0s, visibility 0s .25s;
		visibility: hidden;

		ul {
			list-style: none;
			margin: 0;
			text-align: right;
			padding: 0;

			li {
				margin-bottom: .5rem;

				&:last-child {margin-bottom: 0;}

				a {
					display: block;
					text-decoration: none;
	
					&:after {
						background: $black;
						content: '';
						display: block;
						height: 1px;
						position: relative;
							top: 0;
						transform: scaleX(0);
						transform-origin: 0 100%;
						transition: transform .25s ease-in-out;
					}
		
					&:hover,
					&:focus {
						text-decoration: none;
	
						&:after {
							transform: scaleX(1);
							transform-origin: 100% 0;
						}
					}
				}
			}
		}
	}
}