/**
 * DBS Slate Front Page Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will only be loaded on the front page.
 */
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Border Radius
 */
/**
 * Generate transition delay based on nth-child
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Fonts
 */
/**
 * Type style definitions
 */
.brand-strip__heading {
  max-width: 47rem;
  opacity: 0;
  transition: 0.75s opacity;
  text-align: center; }
  .brand-strip__heading.in-view {
    opacity: 1; }

.brand-strip__heading {
  margin: 0 auto 1.5rem; }

.brand-strip__strip {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: 1.5rem; }
  @media screen and (max-width: 61.999em) {
    .brand-strip__strip {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; } }

.brand-strip__image {
  max-width: 15rem;
  opacity: 0;
  transform: translate3d(0, 2rem, 0);
  transition: 0.75s opacity;
  padding: 1rem; }
  .brand-strip__image:nth-child(2) {
    transition-delay: 0.25s; }
  .brand-strip__image:nth-child(3) {
    transition-delay: 0.5s; }
  .brand-strip__image:nth-child(4) {
    transition-delay: 0.75s; }
  .brand-strip__image:nth-child(5) {
    transition-delay: 1s; }
  .brand-strip__image:nth-child(6) {
    transition-delay: 1.25s; }
  .brand-strip__image:nth-child(7) {
    transition-delay: 1.5s; }
  .brand-strip__image:nth-child(8) {
    transition-delay: 1.75s; }
  .brand-strip__image.in-view {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .brand-strip__image img {
    max-height: 3rem;
    width: auto; }
  @media screen and (min-width: 48em) {
    .brand-strip__image img {
      max-height: 4rem; } }

.brand-strip.align-left .brand-strip__heading {
  margin: 0;
  max-width: none;
  text-align: start; }

.brand-strip.align-left .brand-strip__strip {
  -ms-flex-pack: start;
  justify-content: flex-start; }

.cards .card:nth-child(1) {
  transition-delay: 0s; }

.cards .card:nth-child(2) {
  transition-delay: 0.25s; }

.cards .card:nth-child(3) {
  transition-delay: 0.5s; }

.cards .card:nth-child(4) {
  transition-delay: 0.75s; }

.cards .card:nth-child(5) {
  transition-delay: 1s; }

.cards .card:nth-child(6) {
  transition-delay: 1.25s; }

.cards .card:nth-child(7) {
  transition-delay: 1.5s; }

.cards .card:nth-child(8) {
  transition-delay: 1.75s; }

.cards .card:nth-child(9) {
  transition-delay: 2s; }

.cards .card:nth-child(10) {
  transition-delay: 2.25s; }

.cards .card:nth-child(11) {
  transition-delay: 2.5s; }

.cards .card:nth-child(12) {
  transition-delay: 2.75s; }

.cards .card:nth-child(13) {
  transition-delay: 3s; }

.cards .card:nth-child(14) {
  transition-delay: 3.25s; }

.cards .card:nth-child(15) {
  transition-delay: 3.5s; }

.cards .card:nth-child(16) {
  transition-delay: 3.75s; }

.cards .card:nth-child(17) {
  transition-delay: 4s; }

.cards .card:nth-child(18) {
  transition-delay: 4.25s; }

.cards .card:nth-child(19) {
  transition-delay: 4.5s; }

.cards .card:nth-child(20) {
  transition-delay: 4.75s; }

.cards .animation-trigger.in-view ~ .card {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.cards .contain {
  gap: 1.5rem; }

.cards .card {
  background: #ffffff;
  opacity: 0;
  padding: 1rem;
  position: relative;
  transform: translate3d(0, 2rem, 0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .cards .card__icon {
    height: 0;
    margin-bottom: 1rem;
    max-width: 8rem;
    padding-bottom: 4rem;
    position: relative; }
    .cards .card__icon img,
    .cards .card__icon svg {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .cards .card__icon img,
        .cards .card__icon svg {
          height: 100%;
          object-fit: contain;
          object-position: left; } }
    .cards .card__icon svg path {
      transition: fill .25s; }
  .cards .card__text {
    color: #404144;
    margin-bottom: 1rem; }
    .cards .card__text h1, .cards .card__text h2, .cards .card__text h3, .cards .card__text h4, .cards .card__text h5, .cards .card__text h6,
    .cards .card__text .h1, .cards .card__text .h2, .cards .card__text .h3, .cards .card__text .h4, .cards .card__text .h5, .cards .card__text .h6 {
      color: #11538B;
      font-family: "Montserrat", "Verdana", "Helvetica", sans-serif;
      font-size: 1.125rem;
      margin-bottom: .75rem; }
  .cards .card__link {
    height: 2rem;
    width: 2rem;
    background: url("/icons/arrow-right.svg") no-repeat left;
    background-size: contain;
    display: block;
    transition: transform .25s; }
  .cards .card.linkclicker:hover svg path {
    fill: #F26525; }
  .cards .card.linkclicker:hover .card__link {
    transform: translate3d(0.5rem, 0, 0); }

.cards.numbers .card {
  padding-top: 3.5rem;
  position: relative; }
  .cards.numbers .card::before {
    color: #BAD2E5;
    font-family: "Bebas Neue", "Verdana", "Helvetica", sans-serif;
    font-size: 8rem;
    line-height: 1;
    opacity: .3;
    position: absolute;
    top: 1rem;
    left: 4.5rem; }
  .cards.numbers .card:nth-child(2)::before {
    content: '1'; }
  .cards.numbers .card:nth-child(3)::before {
    content: '2'; }
  .cards.numbers .card:nth-child(4)::before {
    content: '3'; }
  .cards.numbers .card:nth-child(5)::before {
    content: '4'; }
  .cards.numbers .card:nth-child(6)::before {
    content: '5'; }
  .cards.numbers .card:nth-child(7)::before {
    content: '6'; }
  .cards.numbers .card:nth-child(8)::before {
    content: '7'; }
  .cards.numbers .card:nth-child(9)::before {
    content: '8'; }
  .cards.numbers .card:nth-child(10)::before {
    content: '9'; }
  .cards.numbers .card:nth-child(11)::before {
    content: '10'; }
  .cards.numbers .card:nth-child(12)::before {
    content: '11'; }
  .cards.numbers .card:nth-child(13)::before {
    content: '12'; }

@media screen and (min-width: 48em) {
  .cards {
    -ms-flex-align: start;
    align-items: start; }
    .cards .card {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex: 1;
      flex: 1; }
    .cards .card__text--wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      height: 100%; }
      .cards .card__text--wrapper .card__link {
        margin-top: auto; }
    .cards .contain.cards-4 .card {
      -ms-flex: auto;
      flex: auto;
      width: calc(25% - 1.5rem); }
    .cards .contain.cards-5 .card {
      -ms-flex: auto;
      flex: auto;
      width: calc(20% - 1.5rem); }
    .cards .contain.cards-3 .card, .cards .contain.cards-6 .card {
      -ms-flex: auto;
      flex: auto;
      width: calc(33.33% - 1.5rem); } }

.contact-cta__wrapper {
  background: linear-gradient(to right, #061B3C, #11538B);
  opacity: 0;
  padding: 1.5rem;
  position: relative;
  transition: opacity 0.75s; }
  .contact-cta__wrapper.in-view {
    opacity: 1; }

.contact-cta p {
  margin: 0 auto 2rem;
  max-width: 45rem; }

@media screen and (min-width: 48em) {
  .contact-cta__wrapper {
    padding: 3rem; } }

@media screen and (min-width: 62em) {
  .contact-cta__wrapper {
    padding: 4.5rem;
    padding-right: 9rem; }
  .contact-cta .button {
    font-size: 1.125rem;
    padding: 1rem 3rem; } }

.partnerships__text {
  margin-bottom: 3rem;
  opacity: 0;
  transition: opacity 0.75s; }
  .partnerships__text.in-view {
    opacity: 1; }

.partnerships__graphic {
  height: 0;
  padding-bottom: 100%;
  position: relative; }
  .partnerships__graphic--outer, .partnerships__graphic--mid, .partnerships__graphic--inner, .partnerships__graphic--center {
    border-radius: 50%;
    border: 1px solid #dadada;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .partnerships__graphic--outer {
    height: 100%;
    width: 100%; }
  .partnerships__graphic--mid {
    height: 75%;
    width: 75%; }
  .partnerships__graphic--inner {
    height: 50%;
    width: 50%; }
  .partnerships__graphic--center {
    height: 25%;
    width: 25%;
    background-color: white;
    border: none;
    box-shadow: 0.5rem 0.5rem 1rem -0.5rem rgba(0, 0, 0, 0.2); }
    .partnerships__graphic--center .partnerships__graphic--icon {
      height: 50%;
      width: 50%;
      transform: translate(-50%, -50%); }
      .partnerships__graphic--center .partnerships__graphic--icon img {
        height: 100% !important;
        width: 100% !important; }
  .partnerships__graphic--icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center; }
    .partnerships__graphic--icon img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 10%;
      width: 10%; }
      @supports (object-fit: contain) {
        .partnerships__graphic--icon img {
          height: 100%;
          object-fit: contain;
          object-position: top; } }
    .partnerships__graphic--icon::before {
      background: #ffffff;
      border-radius: 50%;
      content: '';
      height: 16%;
      width: 16%;
      position: absolute;
      top: -3%;
      left: 42%; }
    .partnerships__graphic--icon.outer-ring {
      height: 109%;
      width: 109%;
      z-index: 2; }
      .partnerships__graphic--icon.outer-ring:nth-child(1) {
        animation: circlePathZero 27s linear infinite; }
        .partnerships__graphic--icon.outer-ring:nth-child(1)::before {
          box-shadow: 2px 2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.outer-ring:nth-child(2) {
        animation: circlePathQuarter 27s linear infinite; }
        .partnerships__graphic--icon.outer-ring:nth-child(2)::before {
          box-shadow: 2px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.outer-ring:nth-child(3) {
        animation: circlePathHalf 27s linear infinite; }
        .partnerships__graphic--icon.outer-ring:nth-child(3)::before {
          box-shadow: -1px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.outer-ring:nth-child(4) {
        animation: circlePathThreeQuarter 27s linear infinite; }
        .partnerships__graphic--icon.outer-ring:nth-child(4)::before {
          box-shadow: -2px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.outer-ring::before {
        animation: reverseRotate 27s linear infinite; }
    .partnerships__graphic--icon.mid-ring {
      height: 82.5%;
      width: 82.5%; }
      .partnerships__graphic--icon.mid-ring:nth-child(1) {
        animation: circlePathZero 22s linear infinite; }
        .partnerships__graphic--icon.mid-ring:nth-child(1)::before {
          box-shadow: 2px 2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.mid-ring:nth-child(2) {
        animation: circlePathQuarter 22s linear infinite; }
        .partnerships__graphic--icon.mid-ring:nth-child(2)::before {
          box-shadow: 2px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.mid-ring:nth-child(3) {
        animation: circlePathHalf 22s linear infinite; }
        .partnerships__graphic--icon.mid-ring:nth-child(3)::before {
          box-shadow: -1px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.mid-ring:nth-child(4) {
        animation: circlePathThreeQuarter 22s linear infinite; }
        .partnerships__graphic--icon.mid-ring:nth-child(4)::before {
          box-shadow: -2px -2px 1rem -0.5rem rgba(0, 0, 0, 0.5); }
      .partnerships__graphic--icon.mid-ring::before {
        animation: reverseRotate 22s linear infinite; }

@media screen and (min-width: 48em) {
  .partnerships__text {
    margin-bottom: 0;
    padding-right: 4.5rem;
    width: 50%; }
  .partnerships__graphic {
    padding-bottom: 50%;
    width: 50%; } }

@keyframes circlePathZero {
  0% {
    transform: translate(-50%, -50%) rotate(0deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes circlePathQuarter {
  0% {
    transform: translate(-50%, -50%) rotate(90deg); }
  100% {
    transform: translate(-50%, -50%) rotate(450deg); } }

@keyframes circlePathHalf {
  0% {
    transform: translate(-50%, -50%) rotate(180deg); }
  100% {
    transform: translate(-50%, -50%) rotate(540deg); } }

@keyframes circlePathThreeQuarter {
  0% {
    transform: translate(-50%, -50%) rotate(270deg); }
  100% {
    transform: translate(-50%, -50%) rotate(630deg); } }

@keyframes reverseRotate {
  0% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes reverseRotateImg {
  0% {
    transform: rotate(360deg) translate(-50%, -50%); }
  100% {
    transform: rotate(0deg) translate(-50%, -50%); } }

/**
 * Two Columns Flex Layout
 * DBS Interactive
 */
.two-columns__left, .two-columns__right {
  opacity: 0;
  position: relative;
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .two-columns__left img, .two-columns__right img {
    display: block; }
  .two-columns__left.has-img, .two-columns__right.has-img {
    opacity: 0;
    transition: opacity 0.75s ease-in-out; }
  .two-columns__left img, .two-columns__right img {
    display: block; }

.two-columns__left {
  transform: translate3d(-2rem, 0, 0); }

.two-columns__right {
  transform: translate3d(2rem, 0, 0); }

.two-columns .animation-trigger.in-view ~ .has-img {
  opacity: 1; }

.two-columns .animation-trigger.in-view ~ .two-columns__left,
.two-columns .animation-trigger.in-view ~ .two-columns__right {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.two-columns:not(.full-bleed) .has-img.natural::after {
  border-radius: .75rem; }

.two-columns:not(.full-bleed) .has-img.natural img {
  border-radius: .75rem; }
  @media screen and (min-width: 48em) {
    .two-columns:not(.full-bleed) .has-img.natural img {
      border-radius: 1.375rem; } }

@media screen and (max-width: 47.999em) {
  .two-columns__left {
    margin-bottom: 1.5rem; }
  .two-columns.full-bleed .has-img {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .two-columns + .full-bleed {
    margin-top: 1.5rem !important; } }

@media screen and (min-width: 48em) {
  .two-columns:not(.full-bleed) .has-img.natural::after {
    border-radius: 1.375rem; }
  .two-columns:not(.full-bleed) .has-img.natural img {
    border-radius: .75rem; } }
  @media screen and (min-width: 48em) and (min-width: 48em) {
    .two-columns:not(.full-bleed) .has-img.natural img {
      border-radius: 1.375rem; } }

@media screen and (min-width: 48em) {
  .two-columns__left, .two-columns__right {
    width: calc(50% - 1.5rem); }
    .two-columns__left.contain-img img, .two-columns__right.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.contain-img img, .two-columns__right.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-columns__left.cover img, .two-columns__right.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.cover img, .two-columns__right.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .two-columns__left:not(.has-img), .two-columns__right:not(.has-img) {
      padding: 1.5rem 3rem; }
  .two-columns.full-bleed {
    min-height: 30rem;
    position: relative; }
    .two-columns.full-bleed .contain {
      -ms-flex-align: center;
      align-items: center;
      min-height: 30rem; }
    .two-columns.full-bleed .two-columns__left:not(.has-img),
    .two-columns.full-bleed .two-columns__right:not(.has-img) {
      padding: 3rem 0; }
    .two-columns.full-bleed .two-columns__left:not(.has-img) {
      margin-right: auto; }
    .two-columns.full-bleed .two-columns__right:not(.has-img) {
      margin-left: auto; }
    .two-columns.full-bleed .has-img {
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 50%; }
      .two-columns.full-bleed .has-img.two-columns__left {
        left: 0; }
      .two-columns.full-bleed .has-img.two-columns__right {
        right: 0; }
      .two-columns.full-bleed .has-img img {
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%; }
        @supports (object-fit: contain) {
          .two-columns.full-bleed .has-img img {
            height: 100%;
            object-fit: cover;
            object-position: center; } } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-columns.two-thirds-right .two-columns__left {
    width: 38%; } }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-right img {
    height: auto;
    width: 100%; }
  .two-columns.two-thirds-right .two-columns__right.bg-img {
    padding-left: 0; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-columns.two-thirds-right .two-columns__right {
    width: calc(62% - 3rem); } }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-left img {
    height: auto;
    width: 100%; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-columns.two-thirds-left .two-columns__left {
    width: calc(62% - 3rem); }
    .two-columns.two-thirds-left .two-columns__left.bg-img {
      padding-right: 0; }
  .two-columns.two-thirds-left .two-columns__right {
    width: 38%; } }

@media screen and (min-width: 62em) {
  .two-columns.full-bleed .two-columns__left:not(.has-img),
  .two-columns.full-bleed .two-columns__right:not(.has-img) {
    padding: 4.5rem 0; } }

.two-column-collage .animation-trigger.in-view ~ .two-column-collage__text {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.two-column-collage__text {
  opacity: 0;
  position: relative;
  margin-bottom: 3rem;
  transition: opacity 0.75s ease-in-out; }

.two-column-collage__collage {
  height: 0;
  padding-bottom: 100%;
  position: relative; }

.two-column-collage__image {
  border: 2px solid white;
  box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.5);
  height: 0;
  opacity: 0;
  position: absolute;
  transition: 0.75s opacity; }
  .two-column-collage__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
    @supports (object-fit: contain) {
      .two-column-collage__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }
  .two-column-collage__image.in-view {
    opacity: 1; }
  .two-column-collage__image:nth-child(1) {
    top: 5%;
    left: 0;
    padding-bottom: 32%;
    width: 40%; }
  .two-column-collage__image:nth-child(2) {
    top: 0;
    left: 25%;
    padding-bottom: 40%;
    transition-delay: 0.25s;
    width: 60%;
    z-index: 1; }
  .two-column-collage__image:nth-child(3) {
    top: 6%;
    right: 0;
    padding-bottom: 34%;
    transition-delay: 1s;
    width: 40%; }
  .two-column-collage__image:nth-child(4) {
    top: 22%;
    left: 18%;
    padding-bottom: 15%;
    transition-delay: 0.75s;
    width: 20%;
    z-index: 2; }
  .two-column-collage__image:nth-child(5) {
    top: 25%;
    left: 55%;
    padding-bottom: 18%;
    transition-delay: 0.5s;
    width: 28%;
    z-index: 2; }
  .two-column-collage__image:nth-child(6) {
    top: 36%;
    left: 10%;
    padding-bottom: 18%;
    transition-delay: 1.25s;
    width: 25%;
    z-index: 2; }
  .two-column-collage__image:nth-child(7) {
    top: 32%;
    left: 33%;
    padding-bottom: 19%;
    transition-delay: 1.5s;
    width: 30%;
    z-index: 1; }
    .two-column-collage__image:nth-child(7) img {
      object-position: 50% 27%; }
  .two-column-collage__image:nth-child(8) {
    top: 37%;
    left: 62%;
    padding-bottom: 25%;
    transition-delay: 1.75s;
    width: 25%;
    z-index: 1; }
  .two-column-collage__image:nth-child(9) {
    top: 50%;
    left: 15%;
    padding-bottom: 15%;
    transition-delay: 2s;
    width: 25%;
    z-index: 2; }
  .two-column-collage__image:nth-child(10) {
    top: 51%;
    left: 27%;
    padding-bottom: 38%;
    transition-delay: 1.5s;
    width: 63%; }
    .two-column-collage__image:nth-child(10) img {
      object-position: 50% 0%; }

@media screen and (min-width: 48em) {
  .two-column-collage__text {
    margin-bottom: 0;
    padding-right: 3rem;
    width: 50%; }
  .two-column-collage__collage {
    padding-bottom: 50%;
    width: 50%; } }

@media screen and (min-width: 62em) {
  .two-column-collage__text {
    width: 45%; }
  .two-column-collage__collage {
    padding-bottom: 55%;
    width: 55%; } }

/**
 * Two Column w/ Testimonial Flex Layout
 * DBS Interactive
 */
.two-column-with-testimonial .animation-trigger.in-view ~ .has-img {
  opacity: 1; }

.two-column-with-testimonial .animation-trigger.in-view ~ .two-column-with-testimonial__content {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

.two-column-with-testimonial .flickity-page-dots {
  bottom: 1.5rem; }
  .two-column-with-testimonial .flickity-page-dots .dot {
    background: #505D68; }

.two-column-with-testimonial__content, .two-column-with-testimonial__testimonial {
  position: relative; }
  .two-column-with-testimonial__content.has-img, .two-column-with-testimonial__testimonial.has-img {
    opacity: 0;
    transition: opacity 0.75s ease-in-out; }
  .two-column-with-testimonial__content img, .two-column-with-testimonial__testimonial img {
    display: block; }

.two-column-with-testimonial__content {
  opacity: 0;
  transform: translate3d(-2rem, 0, 0);
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .two-column-with-testimonial__content ul {
    list-style-type: none;
    padding-left: 0; }
    .two-column-with-testimonial__content ul li {
      margin-bottom: 1.5rem; }
      .two-column-with-testimonial__content ul li h4 {
        color: #11538B;
        font-family: "Montserrat", "Verdana", "Helvetica", sans-serif;
        margin-bottom: .25rem;
        text-transform: none; }
      .two-column-with-testimonial__content ul li p {
        font-size: 0.875rem; }

.two-column-with-testimonial__testimonial {
  padding: 0 !important;
  position: relative;
  transform: translate3d(2rem, 0, 0); }
  .two-column-with-testimonial__testimonial-wrapper {
    background: linear-gradient(275deg, rgba(226, 236, 244, 0.6) 2.35%, rgba(255, 249, 247, 0.6) 77.23%, transparent 100%);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 1.5rem;
    overflow: hidden;
    padding: 6rem 3.75rem;
    width: 100%; }
    .two-column-with-testimonial__testimonial-wrapper svg {
      height: 71px;
      margin-bottom: 1.5rem;
      position: relative;
      width: 71px;
      z-index: 1; }
    .two-column-with-testimonial__testimonial-wrapper .quote {
      position: relative; }
      .two-column-with-testimonial__testimonial-wrapper .quote::before {
        background: url("/icons/quotes-bg.svg") no-repeat center;
        content: '';
        height: 6rem;
        left: 50%;
        position: absolute;
        top: -2.3rem;
        transform: translateX(-50%);
        width: 8rem; }
    .two-column-with-testimonial__testimonial-wrapper .title {
      color: #11538B; }
  .two-column-with-testimonial__testimonial.bg-blue-testimonials .flickity-page-dots .dot {
    background: #dadada; }
  .two-column-with-testimonial__testimonial.bg-blue-testimonials .two-column-with-testimonial__testimonial-wrapper {
    background: #11538B !important;
    border-radius: 2rem;
    overflow: hidden; }
    .two-column-with-testimonial__testimonial.bg-blue-testimonials .two-column-with-testimonial__testimonial-wrapper p {
      color: #ffffff; }
    .two-column-with-testimonial__testimonial.bg-blue-testimonials .two-column-with-testimonial__testimonial-wrapper .quote::before {
      background: url("/icons/quotes-bg-white.svg");
      height: 389px;
      width: 495px;
      top: -10.3rem; }
    .two-column-with-testimonial__testimonial.bg-blue-testimonials .two-column-with-testimonial__testimonial-wrapper .title {
      font-family: "Bebas Neue", "Verdana", "Helvetica", sans-serif;
      font-size: 1.375rem; }
    .two-column-with-testimonial__testimonial.bg-blue-testimonials .two-column-with-testimonial__testimonial-wrapper.is-selected .quote::before {
      z-index: 3; }

.two-column-with-testimonial.testimonial-left .two-column-with-testimonial__content {
  -ms-flex-order: 1;
  order: 1; }

@media screen and (min-width: 48em) {
  .two-column-with-testimonial .contain {
    padding-left: 1px !important; }
  .two-column-with-testimonial__content:not(.has-img) {
    padding: 1.5rem; }
  .two-column-with-testimonial__content, .two-column-with-testimonial__testimonial {
    width: calc(50% - 1.5rem); }
    .two-column-with-testimonial__content.contain-img img, .two-column-with-testimonial__testimonial.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-column-with-testimonial__content.contain-img img, .two-column-with-testimonial__testimonial.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-column-with-testimonial__content.cover img, .two-column-with-testimonial__testimonial.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-column-with-testimonial__content.cover img, .two-column-with-testimonial__testimonial.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .two-column-with-testimonial__testimonial {
    height: fit-content;
    padding: 1.5rem; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-column-with-testimonial.two-thirds-right .two-column-with-testimonial__content {
    width: 38%; } }

@media screen and (min-width: 48em) {
  .two-column-with-testimonial.two-thirds-right img {
    height: auto;
    width: 100%; }
  .two-column-with-testimonial.two-thirds-right .two-column-with-testimonial__testimonial.bg-img {
    padding-left: 0; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-column-with-testimonial.two-thirds-right .two-column-with-testimonial__testimonial {
    width: calc(62% - 3rem); } }

@media screen and (min-width: 48em) {
  .two-column-with-testimonial.two-thirds-left img {
    height: auto;
    width: 100%; } }

@media screen and (min-width: 48em) and (min-width: 48em) {
  .two-column-with-testimonial.two-thirds-left .two-column-with-testimonial__content {
    width: calc(62% - 3rem); }
    .two-column-with-testimonial.two-thirds-left .two-column-with-testimonial__content.bg-img {
      padding-right: 0; }
  .two-column-with-testimonial.two-thirds-left .two-column-with-testimonial__testimonial {
    width: 38%; } }

@media screen and (max-width: 47.999em) {
  .two-column-with-testimonial__content {
    margin-bottom: 1.5rem; }
  .two-column-with-testimonial__testimonial {
    margin-left: -2rem;
    width: 100%; }
  .two-column-with-testimonial .two-column-with-testimonial__testimonial-wrapper {
    padding: 3rem 1.5rem 4.5rem; }
  .two-column-with-testimonial.testimonial-left .two-column-with-testimonial__content {
    margin-bottom: 0; }
  .two-column-with-testimonial.testimonial-left .two-column-with-testimonial__testimonial {
    margin-bottom: 1.5rem; } }

.admin {
  opacity: .75;
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  transition: opacity .25s; }
  .admin.active, .admin:hover, .admin:focus {
    opacity: 1; }
  .admin.active .admin__links {
    opacity: 1;
    transition: opacity .25s 0s, visibility 0s .0s;
    visibility: visible; }
  .admin__toggle {
    appearance: none;
    background: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.75);
    height: 4rem;
    position: relative;
    width: 4rem; }
    .admin__toggle img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 75%;
      left: 55%;
      width: 75%; }
      @supports (object-fit: contain) {
        .admin__toggle img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
  .admin__links {
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.75);
    opacity: 0;
    padding: 1rem 1.5rem;
    position: absolute;
    bottom: 4.5rem;
    right: 0;
    transition: opacity .25s 0s, visibility 0s .25s;
    visibility: hidden; }
    .admin__links ul {
      list-style: none;
      margin: 0;
      text-align: right;
      padding: 0; }
      .admin__links ul li {
        margin-bottom: .5rem; }
        .admin__links ul li:last-child {
          margin-bottom: 0; }
        .admin__links ul li a {
          display: block;
          text-decoration: none; }
          .admin__links ul li a:after {
            background: #000000;
            content: '';
            display: block;
            height: 1px;
            position: relative;
            top: 0;
            transform: scaleX(0);
            transform-origin: 0 100%;
            transition: transform .25s ease-in-out; }
          .admin__links ul li a:hover, .admin__links ul li a:focus {
            text-decoration: none; }
            .admin__links ul li a:hover:after, .admin__links ul li a:focus:after {
              transform: scaleX(1);
              transform-origin: 100% 0; }

/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */
.site-footer {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), linear-gradient(90deg, #061C2E 0%, #11538B 100%), var(--White, #FFF);
  overflow: hidden;
  clear: both;
  margin-top: auto; }
  .site-footer .contain {
    padding: 0 1.5rem; }
  .site-footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .site-footer nav {
    font-family: "Montserrat", "Verdana", "Helvetica", sans-serif; }
  .site-footer a {
    font-weight: 400;
    text-decoration: none; }
    .site-footer a:hover {
      text-decoration: underline; }
  .site-footer__business {
    margin-bottom: 1.5rem; }
    .site-footer__business ul, .site-footer__business li {
      display: block; }
    .site-footer__business li {
      margin-bottom: .75rem; }
  .site-footer__phone, .site-footer__schedule, .site-footer__email {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    padding-left: 2.25rem;
    position: relative; }
  .site-footer__phone {
    background-image: url("/icons/phone.svg"); }
  .site-footer__schedule {
    background-image: url("/icons/schedule.svg"); }
  .site-footer__email {
    background-image: url("/icons/mail.svg"); }
  .site-footer__top {
    padding: 3rem 0; }
    .site-footer__top--subscribe button.subscribe {
      border-radius: 0;
      border-start-end-radius: .5rem;
      border-end-end-radius: .5rem;
      font-size: .875rem;
      font-weight: 400;
      padding: .75rem 2rem;
      text-transform: uppercase; }
    .site-footer__top--subscribe input[type=email] {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
      margin: 0; }
      .site-footer__top--subscribe input[type=email]:-ms-input-placeholder {
        color: #dadada; }
      .site-footer__top--subscribe input[type=email]::placeholder {
        color: #dadada; }
  .site-footer__navigation .footer-menu__item a {
    display: block;
    font-weight: 400;
    padding: .25rem 0; }
    .site-footer__navigation .footer-menu__item a:after {
      background: #ffffff;
      content: '';
      display: block;
      height: 1px;
      position: relative;
      top: 3px;
      transform: scaleX(0);
      transform-origin: 100% 0;
      transition: transform .25s ease-in-out; }
    .site-footer__navigation .footer-menu__item a:hover, .site-footer__navigation .footer-menu__item a:focus {
      text-decoration: none; }
      .site-footer__navigation .footer-menu__item a:hover:after, .site-footer__navigation .footer-menu__item a:focus:after {
        transform: scaleX(1);
        transform-origin: 0 100%; }
  .site-footer__navigation .footer-menu__item.toplevel {
    margin-bottom: 1rem; }
    .site-footer__navigation .footer-menu__item.toplevel > a {
      font-size: 1.5625em;
      color: #ffffff;
      font-family: "Bebas Neue", "Verdana", "Helvetica", sans-serif;
      font-weight: 700;
      letter-spacing: 1px;
      padding-top: 0; }
  .site-footer__navigation li {
    display: block; }
  .site-footer .social-media {
    margin: 1em 0; }
    .site-footer .social-media__link {
      display: inline-block;
      margin-right: 1rem; }
    .site-footer .social-media a {
      display: block;
      width: 1.5rem; }
      .site-footer .social-media a:hover svg path, .site-footer .social-media a:focus svg path {
        fill: #F26525; }
    .site-footer .social-media svg path {
      fill: #ffffff;
      transition: fill .25s; }
  .site-footer__credits {
    border-top: 1px solid rgba(194, 209, 217, 0.3);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1em 0;
    width: 100%; }
    .site-footer__credits .legal-footer {
      display: inline-block; }
      .site-footer__credits .legal-footer ul, .site-footer__credits .legal-footer li {
        display: inline-block; }
      .site-footer__credits .legal-footer .legal-menu__item {
        margin-right: 2rem; }
  @media screen and (max-width: 61.999em) {
    .site-footer {
      text-align: center; }
      .site-footer__top--subscribe {
        margin: 3rem 0; }
      .site-footer__business {
        margin-left: auto;
        margin-right: auto;
        max-width: 14rem;
        text-align: left; }
      .site-footer .legal-footer {
        margin-bottom: 1.5rem; } }
  @media screen and (min-width: 62em) {
    .site-footer__business {
      margin-bottom: 3rem; }
    .site-footer__navigation > ul {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: start;
      -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
      margin: 0; }
    .site-footer__navigation .footer-menu__item.toplevel {
      padding-right: 1.5rem; }
      .site-footer__navigation .footer-menu__item.toplevel > a {
        font-size: 1.95312em; }
    .site-footer__top--meta {
      width: 25%; }
    .site-footer__top--subscribe {
      width: 25%; }
    .site-footer__top--navigation {
      width: 50%; } }

/**
 * Form Styles
 * DBS>Interactive
 */
.form {
  /**
	* Field Widths/Wrapping
	*/ }
  .form ul {
    list-style: none;
    padding: 0; }
  .form label, .form legend {
    color: #082C4B;
    font-weight: bold; }
  .form__field {
    margin-bottom: 1em; }
  .form .error-text {
    border-bottom: 0.1em solid #F26525;
    color: #F26525;
    padding-bottom: 1em; }
  @media screen and (min-width: 48em) {
    .form {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -.75rem; }
      .form__field-wrapper {
        padding: 0 .75rem;
        width: 100%; }
        .form__field-wrapper.width-25 {
          width: 25%; }
        .form__field-wrapper.width-33 {
          width: 33.33%; }
        .form__field-wrapper.width-50 {
          width: 50%; }
        .form__field-wrapper.width-66 {
          width: 66.66%; }
        .form__field-wrapper.width-75 {
          width: 75%; }
      .form .button {
        margin-left: .75rem; } }

fieldset > label.gfield_label_before_complex,
fieldset legend.gfield_label_before_complex,
.hidden_label > label.gfield_label_before_complex,
.hidden_label legend.gfield_label_before_complex,
.hide-label > label.gfield_label_before_complex,
.hide-label legend.gfield_label_before_complex {
  display: none; }

fieldset > label, fieldset legend,
.hidden_label > label,
.hidden_label legend,
.hide-label > label,
.hide-label legend {
  height: 1px;
  width: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

/**
 * Input Field Styles
 */
textarea {
  min-height: 5em; }

input[type=radio] {
  margin-right: .5em; }

select {
  min-width: 25%;
  margin: 0; }

textarea,
input[type=text]:not([id=search-input]),
input[type=email],
input[type=date],
input[type=number],
input[type=time],
input[type=week],
input[type=month],
input[type=tel],
input[type=search],
input[type=url],
.form__field-wrapper select {
  display: block;
  border: 1px solid #dadada;
  border-radius: 4px;
  margin: .333em 0;
  max-width: 100%;
  padding: .75rem;
  width: 100%; }

input[type=search]::-webkit-search-cancel-button {
  cursor: pointer;
  margin-right: 2rem; }

input[type=search].not-empty {
  background-color: #F1F2F2; }

.form__field-wrapper select option {
  margin-bottom: .2rem; }

fieldset {
  border: none;
  margin-bottom: 3rem;
  padding: 0 !important; }

.form__field-wrapper input[type=checkbox] {
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid #dadada;
  border-radius: 4px;
  margin-right: .5rem; }

.form__field-wrapper input[name="subscribe[]"] + label {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: .5rem; }

/**
 * Validation and error message styling
 */
.error-text {
  color: red; }

/**
 * Animated Form Label
 *
 * Class must be added in the GForms admin
 */
.animate-label {
  position: relative; }
  .animate-label .ginput_complex > span {
    height: 100%;
    width: 100%;
    display: inline-block;
    position: relative; }
    .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label .ginput_complex > span.active label, .animate-label .ginput_complex > span.active legend {
      transform: translateY(-2.25rem) scale(0.75); }
  .animate-label .ginput_complex.gfield_error label {
    transform: translateY(-2.25rem) scale(0.75); }
  .animate-label label, .animate-label legend {
    background: white;
    padding: 0 .25rem;
    position: absolute;
    top: 50%;
    left: .5rem;
    transition: .2s ease-in-out;
    transform: translateY(-50%);
    transform-origin: left; }
  .animate-label.select-field select {
    font-weight: 700;
    padding: 1.5rem .75rem; }
  .animate-label.text-area label {
    top: .5rem;
    transform: none; }
  .animate-label.text-area textarea {
    max-height: 12rem;
    padding-top: 3rem; }
  .animate-label.text-area.gfield_error label, .animate-label.text-area.gfield_error legend {
    transform: none; }
  .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label.active label, .animate-label.active legend {
    transform: translateY(-2.25rem) scale(0.75); }

/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
