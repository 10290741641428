.cards {
    @include transition-delays('.card', 20, 0.25s);
    
    .animation-trigger.in-view ~ {
        .card {
            opacity: 1;
            transform: translate3d(0,0,0);
        }
	}

	.contain {
		gap: 1.5rem;
	}

	.card {
		background: $white;
        opacity: 0;
		padding: 1rem;
		position: relative;
        transform: translate3d(0, 2rem, 0);
        transition: opacity $animationDuration ease-in-out, transform $animationDuration ease-in-out;

		&__icon {
            height: 0;
			margin-bottom: 1rem;
            max-width: 8rem;
            padding-bottom: 4rem;
			position: relative;

			img,
			svg {
                @include object-fit(contain, left);
            }

			svg path {
                transition: fill .25s;
            }
		}

		&__text {
            color: $grayDarker;
			margin-bottom: 1rem;

			h1, h2, h3, h4, h5, h6,
			.h1, .h2, .h3, .h4, .h5, .h6 {
				color: $blue;
				font-family: $font-montserrat;
				font-size: 1.125rem;
				margin-bottom: .75rem;
			}
		}

		&__link {
			@include size(2rem);
			background: url('/icons/arrow-right.svg') no-repeat left;
				background-size: contain;
			display: block;
			transition: transform .25s;
		}

		&.linkclicker:hover {
			svg path {
				fill: $orange;	
			}

			.card__link { transform: translate3d(.5rem, 0,0); }
		}
	}

	&.numbers {
		.card {
			padding-top: 3.5rem;
			position: relative;

			&::before {
				color: #BAD2E5;
				font-family: $font-bebas-neue;
				font-size: 8rem;
				line-height: 1;
				opacity: .3;
				position: absolute;
					top: 1rem;
					left: 4.5rem;
			}

			&:nth-child(2)::before {content: '1';}
			&:nth-child(3)::before {content: '2';}
			&:nth-child(4)::before {content: '3';}
			&:nth-child(5)::before {content: '4';}
			&:nth-child(6)::before {content: '5';}
			&:nth-child(7)::before {content: '6';}
			&:nth-child(8)::before {content: '7';}
			&:nth-child(9)::before {content: '8';}
			&:nth-child(10)::before {content: '9';}
			&:nth-child(11)::before {content: '10';}
			&:nth-child(12)::before {content: '11';}
			&:nth-child(13)::before {content: '12';}
		}
	}

	@include media($screen-sm) {
		align-items: start;

		.card {
			display: flex;
				flex-direction: column;
			flex: 1;
		}

		.card__text--wrapper {
			display: flex;
				flex-direction: column;
			height: 100%;

			.card__link {
				margin-top: auto;
			}
		}

		.contain {
			&.cards-4 {
				.card {
					flex: auto;
					width: calc(25% - 1.5rem);
				}
			}
	
			&.cards-5 {
				.card {
					flex: auto;
					width: calc(20% - 1.5rem);
				}
			}

			&.cards-3,
			&.cards-6 {
				.card {
					flex: auto;
					width: calc(33.33% - 1.5rem);
				}
			}
		}
	}
}